<template>
  <div class="row task-action-master">
    <div class="col-md-12">
      <form @submit.prevent="filterResults()">
        <div class="row py-3 px-4" id="filter-card">
          <div
            class="col-lg-12 col-xl-12 px-0 task-action-search-area py-2 px-2"
            v-if="filters"
          >
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  title="general.search"
                  :placeholder="$t('general.search_filter')"
                  :model.sync="filters.search"
                ></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-1">
                <div class="col-6 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    title="general.start_date"
                    :model.sync="filters.start_date"
                    name="start_time"
                  >
                  </date-filter>
                </div>
                <div class="col-6 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    title="general.end_date"
                    :model.sync="filters.end_date"
                    name="end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0 mt-3">
                <div class="col-4 pr-0">
                  <button
                    @click="filterResults()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-4 pr-0">
                  <button
                    @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
                <div class="col-4 pr-0">
                  <button
                    @click="exportInvoiceTaskTable"
                    type="button"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.export_to_excel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row w-100 mx-0 py-5 pl-xl-12 my-task-nav px-2" v-if="filters">
            <div
              @click="onChangeActiveNav(wait_approve_id)"
              :class="{
                'bg-primary text-white': filters.invoice_status_id === wait_approve_id,
              }"
              class="col-4 py-1 cursor-pointer text-sm align-items-center d-flex justify-content-center font-weight-bolder text-center border primary-border-1px border-right-0 border-radius-left-top-10px border-radius-left-bottom-10px"
            >
              {{ $t("invoice.wait_rejected") }}
            </div>
            <div
              @click="onChangeActiveNav(approve_id)"
              :class="{
                'bg-primary text-white': filters.invoice_status_id === approve_id,
              }"
              class="col-4 py-1 cursor-pointer text-sm align-items-center d-flex justify-content-center font-weight-bolder text-center border primary-border-1px border-right-0"
            >
              {{ $t("invoice.approved") }}
            </div>

            <div
              @click="onChangeActiveNav(rejected_status_id)"
              :class="{
                'bg-primary text-white': filters.invoice_status_id === rejected_status_id,
              }"
              class="col-4 py-1 cursor-pointer text-sm align-items-center d-flex justify-content-center font-weight-bolder text-center border primary-border-1px border-radius-right-top-10px border-radius-right-bottom-10px"
            >
              {{ $t("invoice.rejected") }}
            </div>
            <div class="row w-100 mx-0 py-5 pl-xl-12" v-if="allCheckButtonVisible">
              <div
                class="col-lg-12 col-md-12 col-sm-12 my-2 my-md-0 d-flex justify-content-end"
              >
                <button
                  class="btn btn-sm btn-outline-primary text-sm rounded-full font-weight-bold mt-2 mr-1"
                  @click="allApprove()"
                >
                  {{ $t("invoice.all_approve") }}
                </button>
                <button
                  class="btn btn-sm btn-outline-danger text-sm rounded-full font-weight-bold mt-2 mr-1"
                  @click="allReject()"
                >
                  {{ $t("invoice.all_reject") }}
                </button>
                <button
                  class="btn btn-sm btn-outline-danger text-sm rounded-full font-weight-bold mt-2 mr-1"
                  @click="allCheckClear()"
                >
                  {{ $t("invoice.selected_checkbox_checked_clear") }}
                </button>
              </div>
            </div>
          </div>

          <div class="px-5 py-3">
            <task-action-card
              @checkboxAllClick="checkboxAllClick"
              @checkboxOneClick="checkboxOneClick"
              @onClickReject="onClickToReject"
              @onClickToApprove="onClickToApprove"
              @onClickMultiSelecte="onClickMultiSelected"
              @rowClicked="onRowClicked"
              :items="formattedItems"
              :fields="fields"
            >
            </task-action-card>
            <infinite-loading
              @infinite="onHitBottom"
              :identifier="infiniteId"
              force-use-infinite-wrapper="true"
            >
              <span slot="spinner"></span>
              <span slot="no-more">
                <h4 v-if="filters && filters.page > 2">
                  {{ $t("general.no_more_records") }}
                </h4>
              </span>
              <span slot="no-results">
                <h4>{{ $t("general.no_records_found") }}</h4>
              </span>
            </infinite-loading>
          </div>
          <!-- <div class="px-5 py-3">
            <div class="my-task-action-data-table">
              <data-table id="table" tableVariant="''" :hover="false" :outlined="false" :striped="false"
                :no-border-collapse="true" :borderless="true" :bordered="false" @hitBottom="onHitBottom"
                @rowClicked="onRowClicked" @onClickReject="onClickToReject" @onClickToApprove="onClickToApprove"
                @onClickMultiSelecte="onClickMultiSelected" :items="formattedItems" :fields="fields"
                :sortBy.sync="order" :sortDesc.sync="sort"
                @checkboxAllClick="checkboxAllClick"
                @checkboxOneClick="checkboxOneClick"

                ></data-table>
            </div>
          </div> -->
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import TaskActionCard from "@/view/pages/invoice/layouts/TaskActionCard.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import {
  CREATE_ITEM,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  RESET_VALUES,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  FILTER_TASK_ACTION,
  SET_FILTER_TASK_ACTION,
  TASK_EXPORT,
} from "@/core/services/store/invoice/incoming_invoice.module";

import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import $ from "jquery";

export default {
  name: "TaskAction",
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    DataTable,
    TaskActionCard,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_TASK_ACTION,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedItems() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      return this.items.data.map((invoice) => {
        const orderNumbers = JSON.parse(invoice.order_numbers);
        return {
          id: invoice.id,
          operation_date: moment(invoice.operation_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
          invoice_date: moment(invoice.invoice_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
          company_name: invoice.supplier_company.name,
          order_numbers: orderNumbers.toString(),
          invoice_type: invoice.invoice_type.translations[0].name,
          invoice_kind: invoice.invoice_category.translations[0].name,
          invoice_no: invoice.invoice_no,
          amount: invoice.total_amount + " " + invoice.currency_unit.code,
          invoice_status_id: invoice.status_id ? invoice.status_id : "",
          invoice_status: invoice.status.translations[0].name,
          invoice_value: null,
          is_valid: invoice.is_valid,
          photo: invoice.image_link,
          accounting_description: invoice.accounting_description,
        };
      });
    },
  },
  data() {
    return {
      url: "api/invoice",
      items: { data: [] },
      approve_id: 16,
      infiniteId: 1,
      wait_approve_id: 15,
      rejected_status_id: 19,
      sort: "desc",
      order: "id",
      type: this.$route.params.type,
      allCheckButtonVisible: false,
      fields: [
        {
          key: "company_name",
          label: this.$t("invoice.company_name"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "accounting_description",
          label: this.$t("invoice.accounting_description"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "order_numbers",
          label: this.$t("invoice.order_number"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "operation_date",
          label: this.$t("invoice.operation_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },

        {
          key: "invoice_date",
          label: this.$t("invoice.invoice_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "invoice_type",
          label: this.$t("invoice.invoice_type"),
          sortable: true,
          class:
            "text-center border border-top-0 max-width-125px font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "invoice_kind",
          label: this.$t("invoice.invoice_kind"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "invoice_no",
          label: this.$t("invoice.invoice_number"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "amount",
          label: this.$t("invoice.index_amount"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "approve",
          label: this.$t("invoice.approve"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 text-red-100 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "reject",
          label: this.$t("invoice.reject"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 text-red-100 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER_TASK_ACTION,
    }),
    checkboxAllClick(status) {
      if (status) {
        $(".dt-checkbox input:checkbox").each(function () {
          $(this).prop("checked", true);
        });
        this.allCheckButtonVisible = true;
      } else {
        $(".dt-checkbox input:checkbox").each(function () {
          $(this).prop("checked", false);
        });
        this.allCheckButtonVisible = false;
      }
    },
    checkboxOneClick() {
      console.log('tetiklendi');
      if ($(".dt-checkbox input:checked").length > 0) {
        this.allCheckButtonVisible = true;
      } else {
        this.allCheckButtonVisible = false;
      }
    },
    onChangeActiveNav(value) {
      this.infiniteId++;
      this.filters.invoice_status_id = value;
      this.$store.commit(RESET_VALUES);
    },
    allCheckClear() {
      $(".dt-checkbox input:checkbox").each(function () {
        $(this).prop("checked", false);
      });
      $("#table_check_all").prop("checked", false);
      this.allCheckButtonVisible = false;
    },
    openCreateNewInvoice() {
      this.$router.push({ name: "invoice.create" });
    },
    onHitBottom($state = null) {
      let self = this;
      let filters = _.cloneDeep(this.filters);

      if (this.isUserGranted("Staff", ["invoiceManager"], false, true)) {
        if (filters.invoice_status_id === 19) {
          filters.invoice_status_id = filters.invoice_status_id === 19 ? 24 : 19;
        }

        if (filters.invoice_status_id === 15) {
          filters.invoice_status_id = filters.invoice_status_id === 15 ? 24 : 15;
        }
      }

      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "overhead_invoice", this.type);

      delete filters.approve_id;

      this.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: this.url,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          if (result.status) {
            this.infiniteId++;
            self.items = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
            $(".dt-checkbox input:checkbox").each(function () {
              $(this).prop("checked", false);
            });
            $("#table_check_all").prop("checked", false);
            this.allCheckButtonVisible = false;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    allApprove() {
      let selected = [];
      $(".dt-checkbox input:checked").each(function () {
        let selectedItemId = $(this).attr("id").split("-")[1];
        selected.push(Number(selectedItemId));
      });
      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/change-all-invoice-status/approve",
          contents: {
            id: selected,
          },
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            self.sweetAlertSuccess("invoice.invoice_success_message");
            self.filterResults();
            self.allCheckClear();
          } else {
            let result = response.data.response;
            self.sweetAlertError(result.data.message);
          }
        });
    },
    allReject() {
      let selected = [];
      $(".dt-checkbox input:checked").each(function () {
        let selectedItemId = $(this).attr("id").split("-")[1];
        selected.push(Number(selectedItemId));
      });
      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/change-all-invoice-status/reject",
          contents: {
            id: selected,
          },
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            self.sweetAlertSuccess("invoice.invoice_success_message");
            self.filterResults();
          } else {
            let result = response.data.response;
            self.sweetAlertError(result.data.message);
          }
        });
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        search: null,
        invoice_status_id: null,
        approve_id: null,
        page: 1,
        per_page: 20,
        sort: "",
        order: "",
      });
    },
    onClickMultiSelected(id, select_id) {
      alert(id, select_id);
    },
    onRowClicked(id) {
      this.$router.push({
        name: "invoice.edit.logo",
        params: {
          id: id,
        },
      });
    },
    filterResults() {
      this.filters.page = 1;
      this.onHitBottom(null);
    },
    onClickToReject(id) {
      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: `api/change-invoice-status/` + id + "/reject",
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            self.sweetAlertSuccess("invoice.invoice_success_message");
            self.filterResults();
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    onClickToApprove(id) {
      let item = this.formattedItems.find((item) => item.id === id);
      /* if (item.is_valid === 0) {
         self.sweetAlertError(self.$t('general.invalid_invoice'));
         return false;
       }*/
      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: `api/change-invoice-status/` + id + "/approve",
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            setTimeout(() => {
              self.filterResults();
            }, 500);
            setTimeout(() => {
              self.sweetAlertSuccess("invoice.invoice_success_message");
            }, 1000);
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
        });
    },
    exportInvoiceTaskTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(TASK_EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "invoice_task" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
    if (this.isUserGranted("Staff", ["invoiceManager", "preAccount"], false, true)) {
      this.fields.unshift({
        key: "checkbox",
        label: "",
        sortable: false,
        tdClass: "text-center border border-top-0 font-weight-bolder font-size-lg w-20",
        thClass: "text-nowrap",
      });
    }
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("invoice.invoices") },
    ]);

    if (this.isUserGranted("Staff", ["preAccount"], false)) {
      if (!this.filter) {
        this.wait_approve_id = 15;
        this.approve_id = 16;
        this.resetFilters();
        this.$set(this.filters, "wait_approve_id", this.wait_approve_id);
        this.$set(this.filters, "approve_id", this.approve_id);
        this.$set(this.filters, "invoice_status_id", this.wait_approve_id);
      } else {
        this.wait_approve_id = this.filters.wait_approve_id;
        this.approve_id = this.filters.approve_id;
      }
    }

    if (this.isUserGranted("Staff", ["invoiceManager"], false)) {
      if (!this.filter) {
        this.wait_approve_id = 16;
        this.approve_id = 17;
        this.resetFilters();
        this.$set(this.filters, "wait_approve_id", this.wait_approve_id);
        this.$set(this.filters, "approve_id", this.approve_id);
        this.$set(this.filters, "invoice_status_id", this.wait_approve_id);
      } else {
        this.wait_approve_id = this.filters.wait_approve_id;
        this.approve_id = this.filters.approve_id;
      }
    }

    if (this.isUserGranted("Staff", ["invoiceChief"], false)) {
      if (!this.filter) {
        this.wait_approve_id = 17;
        this.approve_id = 18;
        this.resetFilters();
        this.$set(this.filters, "wait_approve_id", this.wait_approve_id);
        this.$set(this.filters, "approve_id", this.approve_id);
        this.$set(this.filters, "invoice_status_id", this.wait_approve_id);
      } else {
        this.wait_approve_id = this.filters.wait_approve_id;
        this.approve_id = this.filters.approve_id;
      }
    }

    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    sort() {
      this.filterResults();
    },
    order() {
      this.filterResults();
    },
    "filters.invoice_status_id"() {
      this.allCheckClear();
      this.allCheckButtonVisible = false;
      this.filterResults();
    },
  },
};
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/invoice/taskAction.scss";
/*Mobile End*/
</style>
