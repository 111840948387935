<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 d-flex justify-content-end" v-if="isUserGranted('Staff', ['invoiceManager', 'preAccount'], false, true)">
        <span class="text-info">
          <b-form-checkbox
            :id="'check_all'"
            :value="true"
            class="check_all"
            @change="checkboxAllClick"
            size="lg"
          >
          </b-form-checkbox>
        </span>
      </div>
      <div class="layout w-100" v-for="item in items" >
        <div class="col-12 row d-flex justify-content-end mr-0 pr-0 pt-3" v-if="isUserGranted('Staff', ['invoiceManager', 'preAccount'], false, true)">
          <b-form-checkbox
            size="lg"
            :id="`checkbox-` + item.id"
            class="dt-checkbox"
            unchecked-value="not_accepted"
            value="accepted"
            @change="checkboxOneClick"
          >
          </b-form-checkbox>
        </div>
        <div class="inner-layout w-100" @click="onRowClicked(item.id)">
          <div class="px-3 pt-3">

            
            <h3 class="main-heading">{{ item.company_name }}</h3>
            <h3 class="main-heading">{{ item.invoice_no }}</h3>
            <p class="quote1">
              <span> {{ $t("invoice.accounting_description") }} : </span>
              {{ item.accounting_description ? item.accounting_description : "-" }}
            </p>
            <p class="quote1">
              <span> {{ $t("invoice.order_number") }} : </span>
              {{ item.order_numbers ? item.order_numbers : "-" }}
            </p>
            <p class="quote1">
              <span> {{ $t("invoice.amount") }} : </span>
              {{ item.amount }}
            </p>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="d-flex justify-content-between px-3 align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="mdi mdi-calendar-clock date"></i>
                  <span class="quote2 pl-2"
                    ><span>{{ $t("invoice.operation_date") }}: </span>
                    {{ item.operation_date }}</span
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between px-3 align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="mdi mdi-calendar-clock date"></i>
                  <span class="quote2 pl-2"
                    ><span>{{ $t("invoice.invoice_date") }}: </span>
                    {{ item.invoice_date }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex justify-content-between px-3 align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="mdi mdi-format-page-break"></i>
                  <span class="quote2 pl-2"
                    ><span>{{ $t("invoice.invoice_type") }}: </span>
                    {{ item.invoice_type }}</span
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between px-3 align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <i class="mdi mdi-format-page-break"></i>
                  <span class="quote2 pl-2"
                    ><span>{{ $t("invoice.invoice_kind") }}: </span>
                    {{ item.invoice_kind }}</span
                  >
                </div>
              </div>
            </div>
          </div>
  
          <div
            class="d-flex justify-content-between px-3 align-items-center mx-1 my-2 pb-2"
          >
            <div class="d-flex reject">
              <span
                @click.stop="
                  onClickReject(item.id);
                  stopPropagation($event);
                "
                v-html="
                  getIconByKey('icons.invoice.no', {
                    class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
            <div class="ml-auto approve">
              <span
                @click.stop="
                  onClickToApprove(item.id);
                  stopPropagation($event);
                "
                v-html="
                  getIconByKey('icons.invoice.yes', {
                    class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceTaskCard",
  props: {
    infiniteId: {
      default: 1,
      type: Number,
    },
    fields: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    items: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    hover: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkboxAllClick(status) {
      this.$emit("checkboxAllClick", status);
    },
    checkboxOneClick(status) {
      this.$emit("checkboxOneClick", status);
    },
    onRowClicked(id) {
      console.log(id);
      this.$emit("rowClicked", id);
    },
    onClickDelete(id) {
      this.$emit("onClickDelete", id);
    },
    onClickEdit(id) {
      this.$emit("onClickEdit", id);
    },
    onClickReject(id) {
      this.$emit("onClickReject", id);
    },
    onClickToApprove(id) {
      this.$emit("onClickToApprove", id);
    },
    onHitBottom($state) {
      console.log("here on hit bottom");
      this.$emit("hitBottom", $state);
    },
    onClickMultiSelected(payload) {
      let id = payload.name.split("-")[1];
      if (payload.newValue.length || payload.oldValue.length) {
        this.$emit("onClickMultiSelected", { id: id, value: payload.newValue });
      }
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss">
.layout{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 15px;
}
.inner-layout {
  width: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

.main-heading {
  font-size: 1.2rem;
  color: #464e56;
  font-weight: 600;
  margin-bottom: 15px;
}
.quote2 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #868e94;
  text-align: left;
  span {
    font-size: 0.8rem;
    font-weight: 600;
    color: black;
  }
}
.quote1 {
  font-size: 0.9rem;
  font-weight: 500;
  color: #868e94;
  text-align: left;
  span {
    font-size: 0.9rem;
    font-weight: 700;
    color: black;
  }
}
.img1 {
  position: relative;
  left: 20px;
  z-index: 28;
}

.task {
  color: #727475;
}
.date {
  color: #727475;
}
.approve {
  margin-right: 25% !important;
}
.reject {
  margin-left: 25% !important;
}
.imgfix {
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: 2px;
}
</style>
